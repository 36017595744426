
import i18n from 'i18next'
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from 'react-i18next'

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translations: require('./locales/fr/translations.json')
      },
      de: {
        translations: require('./locales/de/translations.json')
      },
      en: {
        translations: require('./locales/en/translations.json')
      },
    },
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations'
  })

i18n.languages = ['fr', 'de', 'en']

export default i18n
