import React, { useState } from "react"
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import LoadingButton from '@mui/lab/LoadingButton'
import Layout from "../layout/Layout"

const ACTIVATE_USER = gql`
  mutation activateUser($email: String! $token: String!) {
    activateUser(email: $email token: $token)
  }
`

export default function AccountActivationPage(props) {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [activateUser, { data, loading, error }] = useMutation(ACTIVATE_USER)

  const onClick = e => {
    setButtonDisabled(true)
    activateUser({ variables: { email: params.get('email'), token: params.get('token') } })
  }

  // todo: add redirect to main page.
//const onActivationSuccess = () => {
//  setTimeout(() => {
//    window.location.href = "/" // todo: Use React for this
//  }, 2800)
//}

  const malformedURL = ((params.get('email') == null) || (params.get('token') == null));
  const success_ = (data?.activateUser === true );
  const error_ = (error || (data?.activateUser === false ));

  return (
    <Layout>
      <Box sx={{ m: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        { malformedURL &&
        <Alert severity="error" >{t('activation-url-malformed')}</Alert>
        }
        { !malformedURL &&
        <Alert severity="info"
          action={
            <LoadingButton color="inherit" size="small"
              loading={loading}
              onClick={onClick}
              disabled={buttonDisabled}
            >{t('do-activate')}</LoadingButton>
            }
        >{t('activation-invitation')}</Alert>
        }
        { success_ &&
        <Alert severity="success">{t('activation-success')}</Alert>
        }
        { error_ &&
        <Alert severity="error" >{t('activation-error')}</Alert>
        }
      </Box>
    </Layout>
  )

}
