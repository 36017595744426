import React, { useState } from 'react'
import { useQuery, gql } from "@apollo/client"
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import LoadingButton from '@mui/lab/LoadingButton'
import { DialogButtonBack, DialogButtonOk } from '../base/DialogButtons'
import { TimeIntervalVsEventsDisplayEnum,
         TimeIntervalVsEventsDisplayDetails } from '../../defs/TimeIntervalVsEventsDisplay'
import './EvqPanel.css';

// todo: implement StartDate and endDate with calendar.

const GET_TIME_INTERVALS = gql`
query getTimeIntervals {
  getTimeIntervals {
    id
    label
  }
}`

export default function ButtonDialogTimeInterval(props) {
  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState(false)
  const [timeIntervalID, setTimeIntervalID] = useState(null)
  const [includeBefore, setIncludeBefore] = useState(true)
  const [includeAfter, setIncludeAfter] = useState(true)

  // eslint-disable-next-line no-unused-vars
  const { loading, error, data } = useQuery(GET_TIME_INTERVALS, {
    onCompleted: data_ => {
      if ((data_?.getTimeIntervals != null) && (data_.getTimeIntervals.length !== 0)) {
        props.onTimeIntervalDefaultChange && props.onTimeIntervalDefaultChange(data_.getTimeIntervals[0].id)
        if (props.timeIntervalID == null) {
          props.onTimeIntervalChange && props.onTimeIntervalChange(data_.getTimeIntervals[0].id)
        }
      }
    }
  })

  const timeIntervals = (data?.getTimeIntervals == null) ? [] : data?.getTimeIntervals

  const onOpen = e => {
    e.preventDefault()
    setTimeIntervalID(props.timeIntervalID)
    switch (props.timeIntervalVsEventsDisplay) {
      case TimeIntervalVsEventsDisplayEnum.ALL:
        setIncludeBefore(true)
        setIncludeAfter(true)
        break;
      case TimeIntervalVsEventsDisplayEnum.STARTING:
        setIncludeBefore(false)
        setIncludeAfter(true)
        break;
      case TimeIntervalVsEventsDisplayEnum.ENDING:
        setIncludeBefore(true)
        setIncludeAfter(false)
        break;
      case TimeIntervalVsEventsDisplayEnum.STARTING_ENDING:
        setIncludeBefore(false)
        setIncludeAfter(false)
        break;
      default:
        ;
    }
    setOpenDialog(true)
  }
  const onClose = e => {
    e.preventDefault()
    setOpenDialog(false)
  }
  const onIntervalChange = (e,value) => {
    e.preventDefault()
    setTimeIntervalID(value)
  }
  const onIncludeBeforeChange = e => {
    e.preventDefault()
    console.log(e)
    setIncludeBefore(e.target.checked)
  }
  const onIncludeAfterChange = e => {
    e.preventDefault()
    console.log(e)
    setIncludeAfter(e.target.checked)
  }
  const onSubmit = e => {
    e.preventDefault()
    if (includeBefore) {
      if (includeAfter) {
        props.onChange(timeIntervalID, TimeIntervalVsEventsDisplayEnum.ALL)
      } else {
        props.onChange(timeIntervalID, TimeIntervalVsEventsDisplayEnum.ENDING)
      }
    } else {
      if (includeAfter) {
        props.onChange(timeIntervalID, TimeIntervalVsEventsDisplayEnum.STARTING)
      } else {
        props.onChange(timeIntervalID, TimeIntervalVsEventsDisplayEnum.STARTING_ENDING)
      }
    }
    setTimeout(() => setOpenDialog(false), 200)
  }
  return (
    <>
      <LoadingButton className='evq-panel-button' onClick={onOpen} disabled={(timeIntervals.length === 0)} loading={loading}>
        {timeIntervals.map(ti => (
          (props.timeIntervalID === ti.id) && t(ti.label)
        ))}
      </LoadingButton>
      <Dialog open={openDialog} onClose={onClose}>
        <DialogContent>
          <List sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '1px' }}>
            {timeIntervals.map(ti => (
              <ListItemButton
                key={ti.id}
                selected={(timeIntervalID ? (timeIntervalID === ti.id) : false )}
                onClick={e => onIntervalChange(e, ti.id)}
                sx={{ p: 2 }}
              >
                <ListItemText primary={t(ti.label)} primaryTypographyProps={{ align: 'center' }} />
              </ListItemButton>
            ))}
          </List>
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={includeBefore} onChange={onIncludeBeforeChange} />} label="Afficher les évènements déjà commencés" />
              <FormControlLabel control={<Checkbox checked={includeAfter}  onChange={onIncludeAfterChange}  />} label="Afficher les évènements se terminant plus tard" />
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <DialogButtonBack onClick={onClose} />
          <DialogButtonOk onClick={onSubmit} />
        </DialogActions>
      </Dialog>
    </>
  )
}
