
class Recents {

  constructor(config) {
    this.config = config
  }

  push(item) {
    if (item == null) {
      return
    }
    const str_ = localStorage.getItem(this.config.storageName)
    const listA_ = (str_ == null) ? [] : JSON.parse(str_);
    const listB_ = [item, ...listA_.filter(listItem => this.config.mismatch(listItem, item))]
    const listC_ = listB_.slice(0, this.config.max)
    localStorage.setItem(this.config.storageName, JSON.stringify(listC_))
  }
  
  delete(item) {
    if (item == null) {
      return
    }
    const str_ = localStorage.getItem(this.config.storageName)
    if (str_ == null) { return }
    const listA_ = JSON.parse(str_);
    const listB_ = listA_.filter(listItem => this.config.mismatch(listItem, item))
    localStorage.setItem(this.config.storageName, JSON.stringify(listB_))
  }

  get() {
    const str_ = localStorage.getItem(this.config.storageName)
    const listA_ = (str_ == null) ? [] : JSON.parse(str_)
    return listA_
  }

  clear() {
    localStorage.clear(this.config.storageName)
  }

}

export const evRecents = new Recents({
  storageName: 'recentEvs',
  mismatch: (listItem, item) => { return (listItem !== item) },
  max: 10
})

var hash = require('object-hash');

export const evqRecents = new Recents({
  storageName: 'recentEvQs',
  mismatch: (listItem, item) => { return (hash(listItem) !== hash(item)) },
  max: 4
})
