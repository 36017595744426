import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import TextFieldPassword from '../base/TextFieldPassword'
import { enqueueSnackbar } from 'notistack'
import { DialogButtonBack, DialogButtonValidate } from '../base/DialogButtons'

const CREATE_USER = gql`
mutation createUser($firstName: String! $password: String! $email: String!) {
  createUser(user: { firstName: $firstName password: $password email: $email })
}
`

export default function DialogSignUp(props) {
  const { t } = useTranslation()
  // eslint-disable-next-line no-unused-vars
  const [createUser, { data, loading, error }] = useMutation(CREATE_USER)

  const onClose = e => {
    props.onClose && props.onClose(e)
  }

  const onSubmit = e => {
    e.preventDefault()
    const form = e.target
    const firstName = form.elements.firstName.value
    const email = form.elements.email.value
    const password = form.elements["new-password"].value
    createUser( {
      variables: { firstName: firstName, password: password, email: email },
      onCompleted: () => {
        enqueueSnackbar(t("on-sign-up-success"), { variant: 'success', autoHideDuration: 10000 })
        onClose()
      },
      onError: err => {
        enqueueSnackbar(t("on-sign-up-error"), { variant: 'error' })
      }
    })
    return true
  }

  return (
    <Dialog open={props.open} keepMounted={props.keepMounted}>
      <DialogTitle>{t('sign-up')}</DialogTitle>
      <DialogContent>
        <form id="create-account-form" onSubmit={onSubmit}>
          <TextField
            variant="filled" margin='dense' fullWidth
            type="text" name="firstName" autoComplete="given-name"
            label={t("form-firstname")}
            required
          />
          <TextField
            variant="filled" margin='dense' fullWidth
            type="email" name="email" autoComplete="email"
            label={t("form-email")}
            required
          />
          <TextFieldPassword
            variant="filled" margin='dense' fullWidth
            name="new-password" autoComplete="new-password"
            label={t("form-password")}
            required
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <DialogButtonBack onClick={onClose} />
        <DialogButtonValidate type="submit" form="create-account-form" />
      </DialogActions>
    </Dialog>
  )
}
