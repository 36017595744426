import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import TextField from '@mui/material/TextField'
import { enqueueSnackbar } from 'notistack'
import { getPlacesByName } from '../../utils/apiGouvFr'
import { getMyPosition } from '../../utils/geolocation'

// todo: implement history of latest places

export default function PlaceAutocomplete(props) {
  const [userLocationOpts, setUserLocationOpts] = useState([])
  const [suggestionOpts, setSuggestionOpts] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [loadingPosition, setLoadingPosition] = useState(false)
  const { t } = useTranslation()

  const onGeolocate = e => {
    e.preventDefault()
    e.stopPropagation()
    const onSuccess_ = place_ => {
      setLoadingPosition(false)
      place_.groupName = 'Ma position'
      setUserLocationOpts([place_])
      props.onChange(place_)
    }
    const onError_ = err => {
      setLoadingPosition(false)
      enqueueSnackbar('cannot get your position', { variant: 'error' }) // todo
    }
    setLoadingPosition(true)
    getMyPosition(onSuccess_, onError_)
  }

  const filterOptions = (options, state) => {
    return options 
  }

  const isOptionEqualToValue = (option, value) => {
    if (option.label === value.label) {
      return true
    }
    else {
      return false
    }
  }

  const onChange = (e, value, reason, details) => {
    // reason: createOption, selectOption, removeOption, blur, clear
    switch (reason) {
      case 'selectOption':
        props.onChange(value)
        break
      default:
        props.onChange(value)
    }
  }

  const onInputChange = (e, value, reason) => {
    switch (reason) {
      case 'input':
        const value_ = value.trim().replace(/\W+/g, ' ')
        switch (value_.length) {
          case 0:
          case 1:
          case 2:
            if (suggestionOpts.length !== 0) {
              setSuggestionOpts([])
            }
            break
          default:
            setLoadingOptions(true)
            getPlacesByName(
              {
                q: value_,
                type: 'municipality',
                limit: 4,
                latlon: props.userLatLon
              },
              places => {
                places.map(place => place.groupName = 'Suggestions')
                setSuggestionOpts(places)
                setLoadingOptions(false)
              },
              error => {
                setSuggestionOpts([])
                setLoadingOptions(false)
              }
            )
        }
        break
      case 'reset':
        if (value === "") {
          setSuggestionOpts([])
        }
        break
      case 'clear':
        setSuggestionOpts([])
        break
      default: 
    }
  }

  const renderInput = props_ => {
    props_.placeholder = t('search-where-placeholder')
    props_.InputProps.startAdornment = (
      <InputAdornment position="start" sx={{ ml: -0.5 }}>
        <IconButton size='large' onClick={onGeolocate}>
          { loadingPosition && <CircularProgress size="1.25rem" /> }
          { (!loadingPosition) && <LocationSearchingIcon color='primary' fontSize='small' /> }
        </IconButton>
      </InputAdornment>
    )
    props_.sx={ background: ((props.value == null) ? '#f0f0f0' : '#ffe5bd')} // todo: set as theme color.
    return (<TextField {...props_} />)
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1 }} >
      <Autocomplete
        options={[...suggestionOpts,...userLocationOpts]}
        renderInput={renderInput}
        blurOnSelect
        clearOnBlur
        filterOptions={filterOptions}
        fullWidth
        groupBy={option => option.groupName}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={loadingOptions}
        noOptionsText="Géolocalisez-vous ou entrez le nom d'une ville, d'une localité ou un code postal."
        onChange={onChange}
        onInputChange={onInputChange}
        value={props.value}
      />
    </Box>
  )
}
