import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

export function DialogButtonBack(props) {
  const { t } = useTranslation()
  return (
    <Button {...props} variant='contained' size='large' disableElevation>{t('button-back')}</Button>
  )
}

export function DialogButtonOk(props) {
  const { t } = useTranslation()
  return (
    <Button {...props} variant='outlined' size='large' disableElevation fullWidth>{t('button-ok')}</Button>
  )
}

export function DialogButtonValidate(props) {
  const { t } = useTranslation()
  return (
    <Button {...props} variant='outlined' size='large' disableElevation fullWidth>{t('button-validate')}</Button>
  )
}
