import Icon from '@mui/material/Icon'
import flagdeutschland from "../../images/flag-deutschland.png"
import flagfrance from "../../images/flag-france.png"
import flaguk from "../../images/flag-uk.png"
import icons8clear48 from '../../images/icons8-clear-48.png'
import icons8close60 from "../../images/icons8-close-60.png"
import icons8close60_white from "../../images/icons8-close-60-white.png" // todo: merge with SVG?
import icons8drag60_0 from "../../images/icons8-drag-0-60.png"
import icons8drag60_1 from "../../images/icons8-drag-1-60.png"
import icons8fantasy60 from "../../images/icons8-fantasy-60.png"
import icons8favorite60 from '../../images/icons8-favorite-60.png'
import icons8json48 from '../../images/icons8-json-48.png'
import icons8key60 from "../../images/icons8-key-60.png"
import icons8mappinpoint60 from "../../images/icons8-map-pinpoint-60.png"
import icons8menu60 from "../../images/icons8-menu-60.png"
import icons8sharerounded60 from '../../images/icons8-share-rounded-60.png'
import icons8target60 from "../../images/icons8-target-60.png"
import icons8town60 from "../../images/icons8-town-60.png"
import icons8trash60 from '../../images/icons8-trash-60.png'
import icons8user64 from "../../images/icons8-user-64.png"
import icons8zoomin60 from "../../images/icons8-zoom-in-60.png"
import icons8zoomout60 from "../../images/icons8-zoom-out-60.png"
import intervaleventsall from '../../images/interval-events-all.png'
import intervaleventsending from '../../images/interval-events-ending.png'
import intervaleventsstarting from '../../images/interval-events-starting.png'
import intervaleventsstartingending from '../../images/interval-events-starting-ending.png'
import usermarker from "../../images/user-marker/user-marker.png"


export function IntervalEventsAllIcon(props) {
  return (<Icon {...props}><img src={intervaleventsall} alt="all-events" /></Icon>)
}

export function IntervalEventsStartingIcon(props) {
  return (<Icon {...props}><img src={intervaleventsstarting} alt="starting-events" /></Icon>)
}

export function IntervalEventsEndingIcon(props) {
  return (<Icon {...props}><img src={intervaleventsending} alt="ending-events" /></Icon>)
}

export function IntervalEventsStartingEndingIcon(props) {
  return (<Icon {...props}><img src={intervaleventsstartingending} alt="starting-ending-events" /></Icon>)
}

export function SignInIcon(props) {
  return (<Icon {...props}><img src={icons8key60} alt="sign-in" /></Icon>)
}

export function SignUpIcon(props) {
  return (<Icon {...props}><img src={icons8fantasy60} alt="sign-up" /></Icon>)
}

export function Screen0Icon(props) {
  return (<Icon {...props}><img src={icons8drag60_0} alt="screen-0" /></Icon>)
}

export function Screen1Icon(props) {
  return (<Icon {...props}><img src={icons8drag60_1} alt="screen-1" /></Icon>)
}

export function CloseIcon(props) {
  return (<Icon {...props}><img src={icons8close60} alt="close" /></Icon>)
}

export function CloseWhiteIcon(props) {
  return (<Icon {...props}><img src={icons8close60_white} alt="close" /></Icon>)
}

export function TargetIcon(props) {
  return (<Icon {...props}><img src={icons8target60} alt="get-home" /></Icon>)
}

export function HomeIcon(props) {
  return (<Icon {...props}><img src={usermarker} alt="show-home" /></Icon>)
}

export function AllMarkersIcon(props) {
  return (<Icon {...props}><img src={icons8mappinpoint60} alt="zoom-all" /></Icon>)
}

export function ZoomInIcon(props) {
  return (<Icon {...props}><img src={icons8zoomin60} alt="zoom-in" /></Icon>)
}

export function ZoomOutIcon(props) {
  return (<Icon {...props}><img src={icons8zoomout60} alt="zoom-out" /></Icon>)
}

export function FlagFrIcon(props) {
  return (<Icon {...props}><img src={flagfrance} alt="fr" /></Icon>)
}

export function FlagDeIcon(props) {
  return (<Icon {...props}><img src={flagdeutschland} alt="de" /></Icon>)
}

export function FlagUkIcon(props) {
  return (<Icon {...props}><img src={flaguk} alt="en" /></Icon>)
}

export function MenuIcon(props) {
  return (<Icon {...props}><img src={icons8menu60} alt="menu" /></Icon>)
}

export function ShareIcon(props) {
  return (<Icon {...props}><img src={icons8sharerounded60} alt="share" /></Icon>)
}

export function TrashIcon(props) {
  return (<Icon {...props}><img src={icons8trash60} alt="share" /></Icon>)
}

export function FavoritesIcon(props) {
  return (<Icon {...props}><img src={icons8favorite60} alt="share" /></Icon>)
}

export function JsonIcon(props) {
  return (<Icon {...props}><img src={icons8json48} alt="JSON" /></Icon>)
}

export function ClearIcon(props) {
  return (<Icon {...props}><img src={icons8clear48} alt="JSON" /></Icon>)
}

export function AccountIcon(props) {
  return (<Icon {...props}><img src={icons8user64} alt="JSON" /></Icon>)
}

export function CityIcon(props) {
  return (<Icon {...props}><img src={icons8town60} alt="JSON" /></Icon>)
}
