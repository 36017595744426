import moment from 'moment'

const dtFormatDayMonthYear = {
  'fr': 'LL',
  'de': 'LL',
  'en': 'LL'
}

const dtFormatDayMonth = {
  'fr': 'D MMMM',
  'de': 'D[.] MMMM',
  'en': 'MMMM Do'
}

const dtFormatWeekday = {
  'fr': 'dddd',
  'de': '[am] dddd',
  'en': '[on] dddd'
}

const dtFormatWeekdayTime = {
  'fr': 'dddd LT',
  'de': '[am] dddd[,] LT',
  'en': '[on] dddd[,] LT'
}

const dtFormatYesterday = {
  'fr': '[Hier]',
  'de': '[Gestern]',
  'en': '[Yesterday]'
}

const dtFormatYesterdayTime = {
  'fr': '[Hier] LT',
  'de': '[Gestern] LT',
  'en': '[Yesterday] LT'
}

const dtFormatToday = {
  'fr': "[Aujourd'hui]",
  'de': '[Heute]',
  'en': "[Today]"
}

const dtFormatTodayTime = {
  'fr': "[Aujourd'hui] LT",
  'de': '[Heute] LT',
  'en': "[Today] LT"
}

const dtFormatTomorrow = {
  'fr': "[Demain]",
  'de': '[Morgen]',
  'en': "[Tomorrow]"
}

const dtFormatTomorrowTime = {
  'fr': "[Demain] LT",
  'de': '[Morgen] LT',
  'en': "[Tomorrow] LT"
}

const dtFormatTime = {
  'fr': 'LT',
  'de': 'LT',
  'en': 'LT'
}

export function dtFormatEvStartEnd(startMoment, endMoment, fullDays) {

  if (startMoment == null) {
    return [null, null]
  }

  const t_now = moment()
  const t_tomorrow = t_now.clone().add(1, 'day')
  const t_yesterday = t_now.clone().subtract(1, 'day')
  const t_week = t_now.clone().add(7, 'day')

  let startMomentFmt = null
  let endMomentFmt = null

  if (startMoment.isSame(t_yesterday, 'day')) {
    if (fullDays) {
      startMomentFmt = dtFormatYesterday
      if (endMoment) {
             if (endMoment.isSame(t_yesterday, 'day'))    { }
        else if (endMoment.isSame(t_now, 'day'))          { endMomentFmt = dtFormatToday }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrow }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekday }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
    else {
      startMomentFmt = dtFormatYesterdayTime
      if (endMoment) {
             if (endMoment.isSame(t_yesterday, 'day'))    { endMomentFmt = dtFormatTime }
        else if (endMoment.isSame(t_now, 'day'))          { endMomentFmt = dtFormatTodayTime }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrowTime }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekdayTime }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
  }
  else if (startMoment.isSame(t_now, 'day')) {
    if (fullDays) {
      startMomentFmt = dtFormatToday
      if (endMoment) {
             if (endMoment.isSame(t_now, 'day'))          { }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrow }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekday }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
    else {
      startMomentFmt = dtFormatTodayTime
      if (endMoment) {
             if (endMoment.isSame(t_now, 'day'))          { endMomentFmt = dtFormatTime }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrowTime }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekdayTime }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
  }
  else if (startMoment.isSame(t_tomorrow, 'day')) {
    if (fullDays) {
      startMomentFmt = dtFormatTomorrow
      if (endMoment) {
             if (endMoment.isSame(t_tomorrow, 'day'))     { }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekday }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
    else {
      startMomentFmt = dtFormatTomorrowTime
      if (endMoment) {
             if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTime }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekdayTime }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
  }
  else if (startMoment.isBetween(t_tomorrow, t_week)) {
    if (fullDays) {
      startMomentFmt = dtFormatWeekday
      if (endMoment) {
             if (endMoment.isSame(startMoment, 'day'))        { }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekday }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
    else {
      startMomentFmt = dtFormatWeekdayTime
      if (endMoment) {
             if (endMoment.isSame(startMoment, 'day'))        { endMomentFmt = dtFormatTime }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekdayTime }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
  }
  else if (startMoment.isSame(t_now, 'year')) {
    if (fullDays) {
      startMomentFmt = dtFormatDayMonth
      if (endMoment) {
             if (endMoment.isSame(startMoment, 'day'))        { }
        else if (endMoment.isSame(t_yesterday, 'day'))    { endMomentFmt = dtFormatYesterday }
        else if (endMoment.isSame(t_now, 'day'))          { endMomentFmt = dtFormatToday }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrow }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekday }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
    else {
      startMomentFmt = dtFormatDayMonth
      if (endMoment) {
             if (endMoment.isSame(startMoment, 'day'))        { }
        else if (endMoment.isSame(t_yesterday, 'day'))    { endMomentFmt = dtFormatYesterdayTime }
        else if (endMoment.isSame(t_now, 'day'))          { endMomentFmt = dtFormatTodayTime }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrowTime }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekdayTime }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
  }
  else {
    if (fullDays) {
      startMomentFmt = dtFormatDayMonthYear
      if (endMoment) {
             if (endMoment.isSame(startMoment, 'day'))        { endMomentFmt = null }
        else if (endMoment.isSame(t_yesterday, 'day'))    { endMomentFmt = dtFormatYesterday }
        else if (endMoment.isSame(t_now, 'day'))          { endMomentFmt = dtFormatToday }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrow }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekday }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
    else {
      startMomentFmt = dtFormatDayMonthYear
      if (endMoment) {
             if (endMoment.isSame(t_yesterday, 'day'))    { endMomentFmt = dtFormatYesterdayTime }
        else if (endMoment.isSame(t_now, 'day'))          { endMomentFmt = dtFormatTodayTime }
        else if (endMoment.isSame(t_tomorrow, 'day'))     { endMomentFmt = dtFormatTomorrowTime }
        else if (endMoment.isBetween(t_tomorrow, t_week)) { endMomentFmt = dtFormatWeekdayTime }
        else if (endMoment.isSame(t_now, 'year'))         { endMomentFmt = dtFormatDayMonth }
        else                                          { endMomentFmt = dtFormatDayMonthYear  }
      }
    }
  }

  return [startMomentFmt, endMomentFmt]

}
