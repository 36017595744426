import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import ButtonDialogAccount from "./ButtonDialogAccount"
import ButtonLanguage from "./ButtonLanguage"
import ButtonMenu from "./ButtonMenu"

function ButtonLogo(props) {
  return (
    <Button {...props}>
      <Typography sx={{ fontFamily: 'Real-Text-ExtraBold', fontSize: '30px', lineHeight: 1, mb: 1.15 }}>lez</Typography>
      <Typography sx={{ ml: 1.2, borderRadius: 0.5, backgroundColor: '#EC5800', color: '#fff', pl: 0.4, pr: 0.4, fontSize: '12px' }}>beta</Typography>
    </Button>
  )
}

export default function Layout(props) {
  return (
    <>
      <AppBar position="sticky" color="secondary" variant="outlined" elevation={0}>
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flex: 100, display: 'flex', justifyContent: 'flex-start', alignItems: 'center',  }}>
              <ButtonMenu />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ButtonLogo href="/" />
            </Box>
            <Box sx={{ flex: 100, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ButtonLanguage />
              <ButtonDialogAccount />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      { (props.subAppBar) && // todo: really used?
      <AppBar position="relative" color="secondary" variant="outlined" elevation={0} sx={{ pt: 1, pb: 1 }}>
        <Container>
          {props.subAppBar}
        </Container>
      </AppBar>
      }
      <Container>
        {props.children}
      </Container>
    </>
  )
}
