import { BrowserRouter, Routes, Route } from "react-router-dom"
import { SnackbarProvider } from 'notistack'
import './App.css'
import './i18n/config.js'
import AppSsoProvider from './AppSsoProvider'
import AppApolloProvider from './AppApolloProvider'
import AppThemeProvider from './AppThemeProvider'
import MainPage from "./ui/pages/MainPage"
import EvPage from './ui/pages/EvPage'
import Page404 from "./ui/pages/Page404"
import AccountActivationPage from "./ui/pages/AccountActivationPage"

export default function App() {
  return (
    <AppSsoProvider>
      <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={3} autoHideDuration={3000} />
      <AppApolloProvider>
        <AppThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<MainPage />} />
              <Route path='/event/:evid' element={<EvPage />} />
              <Route path='/action/activate-account' element={<AccountActivationPage />} />
              <Route path='*' element={<Page404 />} />
            </Routes>
          </BrowserRouter>
        </AppThemeProvider>
      </AppApolloProvider>
    </AppSsoProvider>
  )
}
