import moment from 'moment'

export function doSaveTokens(ctxt, data, armTokenRefreshTimer) {
  // Object {
  //   access_token: "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJGQUZ0MGVzUklDMGpSZ0NCQ0tDV2MzX0NXa2hNS2QwazZhTGNmS0x3NHRRIn0.eyJleHAiOjE3MTI1NzY3NTUsImlhdCI6MTcxMjU3NjQ1NSwiYXV0aF90aW1lIjoxNzEyNTc2NDQ4LCJqdGkiOiIzOWVmNzg5YS1hMDNmLTRhNWQtODRhNi05MDg4OTVjNmY0NDIiLCJpc3MiOiJodHRwczovL3Nzby5sZXpvb20uZnIvcmVhbG1zL0xaIiwic3ViIjoiN2FhNmVmYjctYmExOS00YTRjLTgxMDUtZWMxNmZiMjlhZTc4IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibGV6b29tX2Zyb250ZW5kIiwic2Vzc2lvbl9zdGF0ZSI6ImIxMGU5ZmVhLTYzZmMtNGMzNi1hZDA4LTA1MWU3NDU1MWJkZCIsImFjciI6IjEiLCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiJiMTBlOWZlYS02M2ZjLTRjMzYtYWQwOC0wNTFlNzQ1NTFiZGQiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkN5cmlsIiwiZ3JvdXBzIjpbIkxFWk9PTV9VU0VSIl0sInByZWZlcnJlZF91c2VybmFtZSI6ImN5cmlsaGFiZXJ0QGxhcG9zdGUubmV0IiwiZ2l2ZW5fbmFtZSI6IkN5cmlsIiwiZW1haWwiOiJjeXJpbGhhYmVydEBsYXBvc3RlLm5ldCJ9.eLsCiCMrDTi4BPr0BLh9KBkZWHfT6pq_UutDWQUxiOHhqZ2LDwt3SuYjACo_uIeyspfWLg77enM59k5JvO2_kcMOA9bARUGix8onybe2aFvSyqTsHy_Zr4fnspXNlawiG14TAoTRtZhOT5gKrPFp235DCnwa5UzGf9nl4VWad_lp5EFVJmFw0sfaj0YtDy5QyrEhazne8wzLGe4e3ZgcyrVDcr7JJvjwPF48QDN0NWiMwLM64nLuZjKGxX4UIoADmz7ELNw9v3mClLXNtXGaARc1FKOE0cPGs1eA7nlUiLw0S8jSD4CAp03lgVb2yLVKPCf7oYEmH_dwu-QHjqL04Q",
  //   expires_in: 300,
  //   refresh_expires_in: 1800,
  //   refresh_token: "eyJhbGciOiJIUzUxMiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJmNmFlNGNkZC1kNzVmLTRmYWYtOTUxYS00ODMyYzA2MzI2NTcifQ.eyJleHAiOjE3MTI1NzgyNTUsImlhdCI6MTcxMjU3NjQ1NSwianRpIjoiOTlmMWUxMmMtNjdiYS00NjQ0LThiZTItYTQ3ZjBiN2E3OTc1IiwiaXNzIjoiaHR0cHM6Ly9zc28ubGV6b29tLmZyL3JlYWxtcy9MWiIsImF1ZCI6Imh0dHBzOi8vc3NvLmxlem9vbS5mci9yZWFsbXMvTFoiLCJzdWIiOiI3YWE2ZWZiNy1iYTE5LTRhNGMtODEwNS1lYzE2ZmIyOWFlNzgiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoibGV6b29tX2Zyb250ZW5kIiwic2Vzc2lvbl9zdGF0ZSI6ImIxMGU5ZmVhLTYzZmMtNGMzNi1hZDA4LTA1MWU3NDU1MWJkZCIsInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsInNpZCI6ImIxMGU5ZmVhLTYzZmMtNGMzNi1hZDA4LTA1MWU3NDU1MWJkZCJ9.-XE-QU2NlWB5JGizv21s4XJo74kSs9eqltywPgI-bo40phIc2IRuEtysdi2lf7iqYym7PTEJXU7UMnKQR7nfwg",
  //   token_type: "Bearer",
  //   "not-before-policy": 0,
  //   session_state: "b10e9fea-63fc-4c36-ad08-051e74551bdd",
  //   scope: "profile email"
  // }
  const tokens_ = {
    accessToken: data.access_token,
    accessExpiresOn: moment().add((data.expires_in - ctxt.config.refreshMargin), 'seconds').unix(),
    refreshToken: data.refresh_token,
    refreshExpiresOn: moment().add((data.refresh_expires_in - ctxt.config.refreshMargin), 'seconds').unix()
  }
  // todo: store in memory+cookie.
  localStorage.setItem('sso-tokens', JSON.stringify(tokens_))
  if (data.expires_in < ctxt.config.refreshMargin) {
    armTokenRefreshTimer(data.expires_in * 1000) // todo: implement silent-signin?
  }
  else {
    armTokenRefreshTimer((data.expires_in - ctxt.config.refreshMargin) * 1000)
  }
}

export function doGetTokens() {
  if (localStorage.getItem('sso-tokens') == null) {
    return null
  }
  return JSON.parse(localStorage.getItem('sso-tokens'))
}

export function doClearTokens(disarmTokenRefreshTimer) {
  disarmTokenRefreshTimer()
  localStorage.removeItem('sso-tokens')
}
