import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Paper from '@mui/material/Paper'
import SaveIcon from '@mui/icons-material/Save'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { enqueueSnackbar } from 'notistack'
import ButtonDialogRadius from './ButtonDialogRadius'
import ButtonDialogSortBy from './ButtonDialogSortBy'
import ButtonDialogTimeInterval from './ButtonDialogTimeInterval'
import ButtonDialogTags from './ButtonDialogTags'
import PlaceAutocomplete from '../base/PlaceAutocomplete'
import { SortByEnum } from '../../defs/SortBy'
import TagStack from '../tag/TagStack'
import { TimeIntervalVsEventsDisplayEnum } from '../../defs/TimeIntervalVsEventsDisplay'
import { evqRecents } from '../../model/Recents'
import { ClearIcon } from '../base/Icons'

const defaults = {
  place: null,
  radius: 20,
  timeIntervalID: null,
  timeIntervalVsEventsDisplay: TimeIntervalVsEventsDisplayEnum.ALL,
  tagsIn: [],
  tagsOut: [],
  sortBy: SortByEnum.DATE
}

export default function EvqPanel(props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [defaultEvq, setDefaultEvq] = useState({...defaults})
  const [evq,        setEvq]        = useState({...defaults})

  useEffect(() => {
    if (props.evQ != null) {
      setEvq({...props.evQ})
    }
  }, [props.evQ])

  const onReset = e => {
    e.preventDefault()
    setEvq({...defaultEvq})
  }

  const onRestore = e => {
    e.preventDefault()
    if (props.evQ != null) {
      setEvq({...props.evQ})
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    if (evq.place == null) {
      enqueueSnackbar(t("search-where-help"), { variant: 'info', preventDuplicate: true })
      return
    }
    const evq_ = {...evq}
    evqRecents.push(evq_)
    props.onSubmit(evq_)
  }

  if (useMediaQuery(theme.breakpoints.only('xs'))) {
    //
    // vertical layout
    //
    return (
      <Paper variant='outlined' sx={{ mt: 1, p: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <PlaceAutocomplete
            value={evq.place}
            onChange={value => setEvq({...evq, place: value})}
          />
        </Box>
        <Box sx= {{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '1px' }}>
          <ButtonDialogRadius
            value={evq.radius}
            onChange={value => setEvq({...evq, radius: value})}
          />
          <ButtonDialogTimeInterval
            timeIntervalID={evq.timeIntervalID}
            timeIntervalVsEventsDisplay={evq.timeIntervalVsEventsDisplay}
            onTimeIntervalDefaultChange={value => setDefaultEvq({...defaultEvq, timeIntervalID: value})}
            onTimeIntervalChange={value => setEvq({...evq, timeIntervalID: value})}
            onChange={(id_,display_) => setEvq({...evq, timeIntervalID: id_, timeIntervalVsEventsDisplay: display_})}
          />
          <ButtonDialogTags
            tagsIn={evq.tagsIn}
            tagsOut={evq.tagsOut}
            onTagsInChange={tags => setEvq({...evq, tagsIn: tags})}
            onTagsOutChange={tags => setEvq({...evq, tagsOut: tags})}
          />
          <ButtonDialogSortBy
            value={evq.sortBy}
            onChange={value => setEvq({...evq, sortBy: value})}
          />
        </Box>
        { ((evq.tagsIn.length !== 0) || (evq.tagsOut.length !== 0)) &&
        <Box sx= {{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
          <TagStack tags={evq.tagsIn} />
          <TagStack tags={evq.tagsOut} blacklisted={true} />
        </Box>
        }
        <Box>
          <Button variant="contained" size="large" color='flashy_blue' disableElevation fullWidth onClick={onSubmit}>{t('button-search-text')}</Button>
        </Box>
        <Box>
          <IconButton color='contrasting' onClick={onReset} >
            <ClearIcon />
          </IconButton>
          <IconButton color='contrasting' disabled={(props.evQ == null)} onClick={onRestore} >
            <SettingsBackupRestoreIcon />
          </IconButton>
          <IconButton color='contrasting' disabled={true} >
            <SaveIcon />
          </IconButton>
          <IconButton color='contrasting' disabled={true} >
            <NotificationsIcon />
          </IconButton>
        </Box>
      </Paper>
    )
  }
  else {
    //
    // horizontal layout
    //
    return (
      <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <PlaceAutocomplete
            value={evq.place}
            onChange={value => setEvq({...evq, place: value})}
          />
          <Button variant="contained" size="large" color='flashy_blue' disableElevation onClick={onSubmit}>
            {t('button-search-text')}
          </Button>
        </Box>
        <Paper variant='outlined' disableElevation sx= {{ alignSelf: 'flex-start', p: 1, display: 'flex', justifyContent: 'center' }}>
          <ButtonDialogRadius
            value={evq.radius}
            onChange={value => setEvq({...evq, radius: value})}
          />
          <ButtonDialogTimeInterval
            timeIntervalID={evq.timeIntervalID}
            timeIntervalVsEventsDisplay={evq.timeIntervalVsEventsDisplay}
            onTimeIntervalDefaultChange={value => setDefaultEvq({...defaultEvq, timeIntervalID: value})}
            onTimeIntervalChange={value => setEvq({...evq, timeIntervalID: value})}
            onChange={(id_,display_) => setEvq({...evq, timeIntervalID: id_, timeIntervalVsEventsDisplay: display_})}
          />
          <ButtonDialogTags
            tagsIn={evq.tagsIn}
            tagsOut={evq.tagsOut}
            onTagsInChange={tags => setEvq({...evq, tagsIn: tags})}
            onTagsOutChange={tags => setEvq({...evq, tagsOut: tags})}
          />
          <ButtonDialogSortBy
            value={evq.sortBy}
            onChange={value => setEvq({...evq, sortBy: value})}
          />
        </Paper>
        { ((evq.tagsIn.length !== 0) || (evq.tagsOut.length !== 0)) &&
        <Box sx= {{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
          <TagStack tags={evq.tagsIn} />
          <TagStack tags={evq.tagsOut} blacklisted={true} />
        </Box>
        }
        <Paper variant='outlined' disableElevation sx={{ alignSelf: 'flex-start', p: 1 }} >
          <IconButton color='contrasting' onClick={onReset} >
            <ClearIcon />
          </IconButton>
          <IconButton color='contrasting' disabled={(props.evQ == null)} onClick={onRestore} >
            <SettingsBackupRestoreIcon />
          </IconButton>
          <IconButton color='contrasting' disabled={true} >
            <SaveIcon />
          </IconButton>
          <IconButton color='contrasting' disabled={true} >
            <NotificationsIcon />
          </IconButton>
        </Paper>
      </Box>
    )
  }
}
