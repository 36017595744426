import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { DialogButtonBack, DialogButtonOk } from '../base/DialogButtons'
import './EvqPanel.css';

const unit = 'km'
const min = 1
const max = 80

export default function ButtonDialogRadius(props) {
  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState(false)
  const [radius, setRadius] = useState(min)
  const onOpen = e => {
    e.preventDefault()
    setRadius(props.value)
    setOpenDialog(true)
  }
  const onClose = e => {
    e.preventDefault()
    setOpenDialog(false)
  }
  const onChange = (e,value) => {
    e.preventDefault()
    setRadius(value)
  }
  const onSubmit = e => {
    e.preventDefault()
    props.onChange(radius)
    setOpenDialog(false)
  }
  return (
    <>
      <Button className='evq-panel-button' onClick={onOpen}>
        {t('b-d-radius-text') + ' ' + props.value + ' ' + unit}
      </Button>
      <Dialog open={openDialog} onClose={onClose}>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', gap: 1 }}>
            <Typography variant='body1' display="inline">{t('b-d-radius-text')}</Typography>
            <Typography variant='h4' display="inline" width="2ch"align='center'>{radius}</Typography>
            <Typography variant='body1' display="inline">{unit}</Typography>
          </Box>
          <Box sx={{ mt: 8, mb: 5 }} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
              <DirectionsWalkIcon />
              <DirectionsCarIcon />
            </Box>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
              <Typography variant='caption' display="inline" align='left'>{t('b-d-radius-closer')}</Typography>
              <Typography variant='caption' display="inline" align='right'>{t('b-d-radius-farther')}</Typography>
            </Box>
            <Slider size='small' sx={{ mt: 1 }} value={radius} min={min} max={max} onChange={onChange} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <DialogButtonBack onClick={onClose} />
          <DialogButtonOk onClick={onSubmit} />
        </DialogActions>
      </Dialog>
    </>
  )
}
