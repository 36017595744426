import { getPlacesByLonLat, getCommuneByLatLon } from './apiGouvFr'

function checkPermission(onSuccess, onError) {
  navigator.permissions.query({ name: 'geolocation' })
  .then(
    result => {
      switch (result.state) {
        case 'denied':
          onError && onError("Cannot geolocate you. Permission denied.")
          break
        case 'onError':
          onError && onError("Cannot geolocate you. Permission issue.")
          break
        case 'granted':
        case 'prompt':
          onSuccess()
          break
        default:
          onError && onError("Cannot geolocate you. Unexpected issue with permissions.")
      }
    },
    err => {
      onError && onError("Cannot geolocate you. Permission issue.")
    }
  )
}

function getPosition(onSuccess, onError) {
  navigator.geolocation.getCurrentPosition(
    position => {
      const latLon = {
        lat: position.coords.latitude,
        lon: position.coords.longitude
      }
      onSuccess(latLon)
    },
    err => {
      switch (err.code) {
        case 1:
          onError && onError("Impossible de vous géolocaliser. Permission refusée.")
          break
        case 2:
          onError && onError("Impossible de vous géolocaliser. Position indisponible.")
          break
        case 3:
          onError && onError("Impossible de vous géolocaliser. Temps dépassé.")
          break
        default:
          onError && onError("Impossible de vous géolocaliser. Problème inattendu.")
      }
    },
    { enableHighAccuracy: true, timeout: 5000 }
  )

}

export function geolocate(onSuccess, onError = null) {
  if (!navigator.geolocation) {
    onError && onError("Impossible de vous géolocaliser. Non-supporté par votre appareil.")
  }
  else {
    if (navigator.permissions) {
      checkPermission(() => getPosition(onSuccess, onError), onError)
    }
    else {
      getPosition(onSuccess, onError)
    }
  }
}

export function getMyPosition(onSuccess, onError) {
  geolocate(
    latLon_ => {
      const place1_ = {
        lat: latLon_.lat,
        lon: latLon_.lon,
        label: latLon_.lat.toString() + ', ' + latLon_.lon.toString()
      }
      getPlacesByLonLat(
        latLon_,
        places => {
          if (places.length > 0) {
            const place2_ = {
              lat: latLon_.lat,
              lon: latLon_.lon,
              label: places[0].label
            }
            onSuccess && onSuccess(place2_)
          }
          else {
            getCommuneByLatLon(
              latLon_,
              communeData => {
                const place3_ = {
                  lat: latLon_.lat,
                  lon: latLon_.lon,
                  label: communeData.nom + ', ' + communeData.codesPostaux[0]
                }
                onSuccess && onSuccess(place3_)
              },
              err => {
                onError && onError(err)
              }
            )
          }
        },
        err => {
          onSuccess && onSuccess(place1_)
        }
      )
    },
    err => {
      onError && onError(err)
    }
  )
}
