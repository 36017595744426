
/*global google*/

import React from 'react'
import { LoadScript, GoogleMap } from '@react-google-maps/api'
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer"
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { TargetIcon, HomeIcon, AllMarkersIcon, ZoomInIcon, ZoomOutIcon } from '../base/Icons'

// ---------------------------------------------------------------------------
// POI
// ---------------------------------------------------------------------------

class POI {

  constructor(latlng, map_ = null, cluster_ = null) {
    this._latlng = latlng  // LatLngLiteral
    this._map = map_
    this._cluster = cluster_
    this._eventsById = {}
    this._marker = null
    this._infowindow = null
    this._onMarkerClick = this._onMarkerClick.bind(this)
    this._createMarker()
  }

  get latlng() {
    return this._latlng
  }

  get title() {
    const len = Object.keys(this._eventsById).length
    if (len === 1) {
      return Object.values(this._eventsById)[0].name
    }
    else {
      return len.toString() + " évènements"
    }
  }

  setMapAndCluster(map_, cluster_) {
    this._map = map_
    this._cluster = cluster_
    this._createMarker()
  }

  _createMarker() {
    if (this._map == null) {
      return
    }
    if (this._marker != null) {
      console.error("unexpected POI.addMarker()")
      this._deleteMarker()
    }
    this._marker = new google.maps.Marker({
      map: this._map,
      position: this._latlng,
      icon: {
        url: "https://img.icons8.com/ios-glyphs/30/fd3737/marker--v1.png",
        scaledSize: new google.maps.Size(24, 24)
      },
      optimized: true
    })
    this._marker.addListener("click", this._onMarkerClick)
    this._cluster.addMarker(this._marker)
  }

  _onMarkerClick() {
    const contentString = '<div><h4>' + this.title + '</h4></div>'
    this._infowindow = new google.maps.InfoWindow({ content: contentString })
    this._infowindow.open({ map: this._map, anchor: this._marker })
  }

  _deleteMarker() {
    this._marker.setMap(null)
    this._cluster.removeMarker(this._marker)
    delete this._marker
  }

  addEvent(event_) {
    this._eventsById[event_.id] = event_
    if (this._marker) {
      // todo: update/hide infoWindow?
    }
  }

  removeEventById(evid) {
    if (evid in this._eventsById) {
      delete this._eventsById[evid]
      if (this._marker) {
      // todo: update/hide infoWindow?
    }
    }
  }

  numEvents() {
    return Object.keys(this._eventsById).length
  }

  destroy() {
    if (this._marker != null) {
      this._deleteMarker()
    }
  }

//  setHighlight() {
//    if (this._marker != null) {
//      this._cluster.removeMarker(this._marker)
//      this._marker.setMap(this._map)
//      this._marker.setAnimation(google.maps.Animation.BOUNCE)
//      this._map.panTo(this.latlng)
//      this._map.setZoom(13)
//    }
//  }
//
//  unsetHighlight() {
//    if (this._marker != null) {
//      this._marker.setAnimation(null)
//      this._cluster.addMarker(this._marker)
//    }
//  }

}

// ---------------------------------------------------------------------------
// userPlace
// ---------------------------------------------------------------------------

class userPlace {

  constructor() {
    this._latlng = null // LatLngLiteral
    this._map = null
    this._marker = null
  }

  set latlng(value) {
    this._latlng = value
    this._updateMarker()
  }

  set map(value) {
    this._map = value
    this._updateMarker()
  }

  get marker() {
    return this._marker
  }

  _updateMarker() {
    if (this._map == null) {
      if (this._marker) {
        this._marker.setMap(null)
        delete this._marker
      }
    }
    else if (this._latlng == null) {
      if (this._marker) {
        this._marker.setMap(null)
        delete this._marker
      }
    }
    else if (this._marker) {
      if ((this._marker.getPosition().lat !== this._latlng.lat) ||
          (this._marker.getPosition().lng !== this._latlng.lng)) {
        this._marker.setPosition(this._latlng)
      }
    }
    else {
      this._marker = new google.maps.Marker({
        map: this._map,
        position: this._latlng,
        icon: {
          url: "https://" + window.location.host + "/user-marker.png",
          scaledSize: new google.maps.Size(28, 28)
        },
        optimized: true
      })
    }
  }

}

// ---------------------------------------------------------------------------
// EventMap
// ---------------------------------------------------------------------------

export default class EventMap extends React.Component {

  constructor(props) {
    super(props)
    this._map = null
    this._cluster = null
    this._POIs = []
    this._POIsByEvid = {}
    this._userPlace = new userPlace()
    this.onMapLoad = this.onMapLoad.bind(this)
    this.onButtonAllMarkersClick = this.onButtonAllMarkersClick.bind(this)
    this.onButtonZoomInClick = this.onButtonZoomInClick.bind(this)
    this.onButtonZoomOutClick = this.onButtonZoomOutClick.bind(this)
    this.onButtonHomeClick = this.onButtonHomeClick.bind(this)
  }

  render() {
    return (
      <>
        <LoadScript googleMapsApiKey="AIzaSyDYHocXbzAacgX3cFY1y3a9ypplqQIKn6Q">
          <GoogleMap
            options={{
              mapId: "3c3a1b0fbd0511fe",
              disableDefaultUI: true,
              keyboardShortcuts: false,
              gestureHandling: "greedy"
            }}
            mapContainerStyle={{
              width: '100%',
              height: '100%'
            }}
            center={{
              lat: 43.72631006408639,
              lng: 5.550061501737741
            }}
            zoom={13}
            onLoad={this.onMapLoad}
          />
        </LoadScript>
        <Box sx={{ position: 'absolute', top: 0, left: 0, background: 'white', padding: 1, borderBottomRightRadius: 5 }}>
          <Box sx={{ display: 'flex' }}>
            { !this.props.userLatLon && 
              <IconButton onClick={this.props.onUserLatLonRequest}><TargetIcon fontSize='small' /></IconButton>
            }
            { this.props.userLatLon &&
              <IconButton onClick={this.onButtonHomeClick}><HomeIcon fontSize='small' /></IconButton>
            }
            <IconButton onClick={this.onButtonAllMarkersClick}><AllMarkersIcon fontSize='small' /></IconButton>
            <IconButton onClick={this.onButtonZoomInClick}><ZoomInIcon fontSize='small' /></IconButton>
            <IconButton onClick={this.onButtonZoomOutClick}><ZoomOutIcon fontSize='small' /></IconButton>
          </Box>
        </Box>
      </>
    )
  }

  componentDidMount() {
    if (this.props.userLatLon != null) {
      this._userPlace.latlng = { lat: this.props.userLatLon.lat, lng: this.props.userLatLon.lon }
    }
    if (this.props.events.length !== 0) {
      this.processEvents([], this.props.events)
      this.showAllMarkers()
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    if (this.props.userLatLon !== prevProps.userLatLon) {
      this._userPlace.latlng = { lat: this.props.userLatLon.lat, lng: this.props.userLatLon.lon }
      if (prevProps.userLatLon == null) {
        this.showHome() // todo: rework
      }
    }
    if (this.props.events !== prevProps.events) {
      const eventsRemoved = prevProps.events.filter(x => !this.props.events.includes(x))
      const eventsAdded = this.props.events.filter(x => !prevProps.events.includes(x))
      if ((eventsRemoved.length === 0) && (eventsAdded.length === 0)) { return }
      this.processEvents(eventsRemoved, eventsAdded)
      if (prevProps.events.length === 0) {
        this.showAllMarkers()
      }
    }
  }

  onMapLoad(map) {
    if (map == null) {
      console.error("onMapLoad() gets map == null")
      return
    }
    if (map === this._map) {
      console.debug("onMapLoad() gets at least twice the same map")
      return
    }
    this._map = map
    const algorithm_ = new SuperClusterAlgorithm({
      minZoom: 0,
      maxZoom: 16,
      radius: 40,
      minPoints: 2
    })
    const renderer_ = {
      render({ count, position }, stats) {
        const svg = window.btoa(`<svg fill="#fd3737" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><circle cx="25" cy="25" opacity=".8" r="25" /></svg>`)
        return new google.maps.Marker({
          position,
          icon: { url: `data:image/svg+xmlbase64,${svg}`, scaledSize: new google.maps.Size(50, 50) }, // todo: do we need this if we control the svg size ?
          label: { text: String(count), color: "rgba(255,255,255,0.9)", fontSize: "12px" },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        })
      }
    }
    this._cluster = new MarkerClusterer({
      map: this._map,
      algorithm: algorithm_,
      renderer: renderer_
    })
    for (const POI_ of this._POIs) {
      POI_.setMapAndCluster(this._map, this._cluster)
    }
    this._userPlace.map = map
    this.showAllMarkers()
  }

  onButtonZoomInClick() {
    this.zoomIn()
  }

  onButtonZoomOutClick() {
    this.zoomOut()
  }

  onButtonHomeClick() {
    this.showHome()
  }

  onButtonAllMarkersClick() {
    this.showAllMarkers()
  }

  zoomIn() {
    if (this._map == null) { return }
    let zoom_ = this._map.getZoom()
    zoom_ += 1
    this._map.setZoom(zoom_)
  }

  zoomOut() {
    if (this._map == null) { return }
    let zoom_ = this._map.getZoom()
    zoom_ -= 1
    this._map.setZoom(zoom_)
  }

  showAllMarkers() {
    if (this._map == null) { return }
    if (this._POIs.length === 0) {
      return
    }
    else if (this._POIs.length === 1) {
      this._map.panTo(this._POIs[0].latlng)
    }
    else {
      const bounds = new google.maps.LatLngBounds()
      this._POIs.forEach((POI_) => {
        bounds.extend(POI_.latlng)
      })
      this._map.fitBounds(bounds)
    }
  }

  showHome() {
   if (this._userPlace.marker == null) { return }
   this._showMarker(this._userPlace.marker)
  }

  _showMarker(marker_) {
    if (this._map == null) { return }
    if (marker_.getPosition() == null) { return }
    if (!this._map.getBounds().contains(marker_.getPosition())) {
      // todo: improve with zoom out style.
      const bounds_ = this._map.getBounds()
      bounds_.extend(marker_.getPosition())
      this._map.fitBounds(bounds_)
    }
    marker_.setAnimation(google.maps.Animation.BOUNCE)
    setTimeout(() => { marker_.setAnimation(null) }, 1500)
  }

  //
  // Events & POIs
  //

  processEvents(eventsRemoved, eventsAdded) {
    for (const event_ of eventsRemoved) {
      this.removeEvent(event_)
    }
    for (const event_ of eventsAdded) {
      if (!event_.location.latitude || (event_?.isTrashed === true)) {
        continue
      }
      if (event_.id in this._POIsByEvid) {
        console.error("unexpected known event")
        continue
      }
      this.addEvent(event_)
    }
    let nextPOIs = []
    for (let i = this._POIs.length - 1; i >= 0; i--) {
      if (this._POIs[i].numEvents() === 0) {
        this._POIs[i].destroy()
        delete this._POIs[i]
      }
      else {
        nextPOIs.push(this._POIs[i])
      }
    }
    this._POIs = nextPOIs
  }

  addEvent(event_) {
    let P_ = null
    for (const POI_ of this._POIs) {
      if ((event_.location.latitude === POI_.latlng.lat) &&
          (event_.location.longitude === POI_.latlng.lng)) {
        P_ = POI_
        break
      }
    }
    if (P_ == null) {
      P_ = new POI({ lat: event_.location.latitude, lng: event_.location.longitude }, this._map, this._cluster)
      this._POIs.push(P_)
    }
    P_.addEvent(event_)
    this._POIsByEvid[event_.id] = P_
  }

  removeEvent(event_) {
    if (event_.id in this._POIsByEvid) {
      const POI_ = this._POIsByEvid[event_.id]
      POI_.removeEventById(event_.id)
      delete this._POIsByEvid[event_.id]
    }
  }

  //   animateTile() {
  // //    this.tile.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"})
  // //    this.tile.style.backgroundColor = "#f6f5f4"
  // //    window.setTimeout(() => { this.tile.style.backgroundColor = "transparent" }, 1000)
  //   }
  // 
  //   animateMarker() {
  //     if (!(this._map.getBounds().contains(this.latLon))) {
  //       let zoom_ = this._map.getZoom()
  //       zoom_ -= 1
  //       this._map.setZoom(zoom_)
  //       window.setTimeout(() => { this.animateMarker() }, 200)
  //       return
  //     }
  //     if (this._marker.getAnimation() == null) {
  //       this._marker.setAnimation(google.maps.Animation.BOUNCE)
  //       window.setTimeout(() => { this._marker.setAnimation(null) }, 2000)
  //     }
  //   }

}
