import { ThemeProvider, createTheme } from '@mui/material/styles'

export default function AppThemeProvider(props) {
  const theme = createTheme({
    palette: {
      primary: { // black
        main: "#000",
        contrastText: '#fff',
      },
      secondary: { // white
        main: "#fff",
        contrastText: '#000',
      },
      contrasting: {
        main: "#000",
      },
      flashy_blue: {
        main: '#87aabf',
        dark: '#557081', // dark is lighter... todo: apply color on button differently.
        contrastText: '#fff',
      },
      roadYellow: {
        main: '#f0b159',
        dark: '#f0bc73',
        contrastText: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'BrandonText-Regular',
      button: {
        textTransform: 'none'
      }
    },
  })
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )  
}
