import Alert from '@mui/material/Alert'
import Layout from "../layout/Layout"

export default function Page404() {
  return (
    <Layout>
      <Alert severity="error" sx={{m: 1}}>La page n'a pas été trouvée.</Alert> 
    </Layout>
  )

}
