import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton'
import Skeleton from "@mui/material/Skeleton";
import Typography from '@mui/material/Typography';

export function EvCardLine(props) {
  const onClick = (props.onClick == null) ? null : e => { props.onClick(e, props.ev?.id, props.ev) }
  const onClose = (props.onClose == null) ? null : e => { props.onClose(e, props.ev?.id, props.ev) }
  return (
    <Card elevation={0} sx={{ borderRadius: 1, display: 'flex', gap: 0, alignItems: 'center' }}>
      <CardActionArea sx={{ m: '2px', p: 1, pl: 2, pr: 2 }} onClick={onClick}>
        <Typography variant='body1'>{(props.ev == null) ? <Skeleton width='100px' /> : props.ev.name}</Typography>
      </CardActionArea>
      <Divider orientation="vertical" flexItem variant="middle" />
      <IconButton size='small' sx={{ m: 0.5 }} onClick={onClose}>
        <CloseIcon fontSize='small' color='contrasting' />
      </IconButton>
    </Card>
  )
}
