//
// https://github.com/geocoders/geocodejson-spec/tree/master/draft
//

export function geocodeToPlace(data) {
  const place_ = {
    lon: data.geometry.coordinates[0],
    lat: data.geometry.coordinates[1]
  }
  switch (data.properties.type) {
    case ('housenumber'):
    case ('street'):
      place_.label = data.properties.label
      break
    case ('locality'):
    case ('municipality'):
      place_.label = data.properties.label + ' (' + data.properties.postcode + ')'
      break
    default:
      ;      
  }
  return place_
}

export function getPlacesByName(args, onSuccess=null, onError=null) {
  if (args.q.length < 3) {
    onSuccess([])
    return
  }
  const params = new URLSearchParams()
  params.set('q', args.q)
  if ('type' in args) {
    params.set('type', args.type)
  }
  if ('limit' in args) {
    params.set('limit', args.limit)
  }
  if (('latlon' in args) && (args.latlon != null)) {
    params.set('lon', args.latlon.lon)
    params.set('lat', args.latlon.lat)
  }
  fetch('https://api-adresse.data.gouv.fr/search/?' + params.toString(), {
    headers: { Accept: "application/json" },
    method: 'GET',
  })
  .then(
    response => response.json()
  )
  .then(
    response => {
      const list_ = []
      if ('features' in response) {
        response.features.forEach(data => {
          const place_ = geocodeToPlace(data)
          list_.push(place_)
        })
      }
      onSuccess(list_)
    }
  )
  .catch(onError)
}

export function getPlacesByLonLat(args, onSuccess=null, onError=null) {
  const params = new URLSearchParams()
  params.set('lon', args.lon)
  params.set('lat', args.lat)
  if ('type' in args) {
    params.set('type', args.type)
  }
  fetch('https://api-adresse.data.gouv.fr/reverse/?' + params.toString(), {
    headers: { Accept: "application/json" },
    method: 'GET',
  })
  .then(
    response => response.json()
  )
  .then(
    response => {
      const list_ = []
      if ('features' in response) {
        response.features.forEach(data => {
          const place_ = geocodeToPlace(data)
          list_.push(place_)
        })
      }
      onSuccess(list_)
    }
  )
  .catch(onError)
}

export function getCommuneByLatLon(args, onSuccess=null, onError=null) {
  const params = new URLSearchParams()
  params.set('lon', args.lon)
  params.set('lat', args.lat)
  fetch('https://geo.api.gouv.fr/communes?' + params.toString(), {
    headers: { Accept: "application/json" },
    method: 'GET',
  })
  .then(
    response => response.json()
  )
  .then(
    response => {
      if (response.length === 0) {
        onError && onError()
      }
      else {
        onSuccess && onSuccess(response[0])
      }
    }
  )
  .catch(onError)
}
