import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft'

export const SortByEnum = Object.freeze({
  DIST: '0',
  DATE: '2',
  NEW: '4'
})

export const SortByDetails = Object.freeze({
  '0': {
    LABEL: 'sort-by-dist-label',
    HELP: 'sort-by-dist-help',
    ICON: <RoundaboutLeftIcon color='contrasting' />
  },
  '2': {
    LABEL: 'sort-by-date-label',
    HELP: 'sort-by-date-help',
    ICON: <CalendarTodayIcon color='contrasting' />
  },
  '4': {
    LABEL: 'sort-by-new-label',
    HELP: 'sort-by-new-help',
    ICON: <NewReleasesIcon color='contrasting' />
  }
})
