import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { Screen0Icon, Screen1Icon, CloseIcon } from '../base/Icons'

export default class BoxResizable extends React.Component {

  constructor(props) {
    super(props)
    this._height = [500, 300]
    this._yRef = null
    this._yRun = 0
    this.state = { mode: 0, height: this._height[0] }
    this.onButtonModeMouseDown = this.onButtonModeMouseDown.bind(this)
    this.onButtonModeMouseMove = this.onButtonModeMouseMove.bind(this)
    this.onButtonModeMouseUp = this.onButtonModeMouseUp.bind(this)
  }

  render() {
    return (
      <Drawer anchor='bottom' variant='persistent' PaperProps={{ elevation: 10 }} open={this.props.open} >
        <Box sx={{ width: '100%', height: this.state.height }} >
          <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 9999, display: 'flex', background: 'white', padding: 1, borderBottomLeftRadius: 5 }}>
          <IconButton onMouseDown={this.onButtonModeMouseDown}>
              {(this.state.mode === 0) && <Screen0Icon fontSize='small' />}
              {(this.state.mode === 1) && <Screen1Icon fontSize='small' />}
            </IconButton>
            <IconButton onClick={this.props.onClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
          {this.props.children}
        </Box>
      </Drawer>
    )
  }

  onButtonModeMouseDown(e) {
    this._yRef = this.state.height + e.clientY
    this._yRun = 0
    document.addEventListener("mouseup", this.onButtonModeMouseUp)
    document.addEventListener("mousemove", this.onButtonModeMouseMove)
  }

  onButtonModeMouseMove(e) {
    const height_ = this._yRef - e.clientY
    this._yRun += Math.abs(this.state.height - height_)
    if (this.props.minHeight && (height_ < this.props.minHeight)) { return }
    this.setState({ height: height_ })
  }

  onButtonModeMouseUp(e) {
    document.removeEventListener("mouseup", this.onButtonModeMouseUp)
    document.removeEventListener("mousemove", this.onButtonModeMouseMove)
    if (this._yRun < 6 /* pixels */) {
      this.onButtonModeClick(e)
    }
    else {
      this._height[this.state.mode] = this.state.height
    }
  }

  onButtonModeClick(e) {
    const mode_ = (this.state.mode + 1) % 2;
    this.setState({ mode: mode_, height: this._height[mode_] })
  }

}
