import Stack from '@mui/material/Stack'
import TagChip from './TagChip'

export default function TagStack(props) {
  return (
    <Stack
      direction={props.direction || "row"}
      alignItems={props.alignItems || "flex-start" }
      spacing={0.5}
      sx={props.sx}
    >
      {props.tags && props.tags.map((tag, idx) => (
        <TagChip
          key={idx}
          tag={tag}
          size={props.size}
          blacklisted={props.blacklisted}
          onClick={props.onTagClick}
          onDelete={props.onTagDelete}
        />
      ))}
    </Stack>
  )
}

