import {
    IntervalEventsAllIcon, 
    IntervalEventsStartingIcon,
    IntervalEventsEndingIcon,
    IntervalEventsStartingEndingIcon } from '../ui/base/Icons'

// TODO: renaming in spec as follows.
//
// dtWiMo         -> TimeIntervalVsEventsDisplayEnum
// INCLUSIVE      -> ALL
// EXCLUDE_BEFORE -> STARTING
// EXCLUDE_AFTER  -> ENDING
// EXCLUSIVE      -> STARTING_ENDING

export const TimeIntervalVsEventsDisplayEnum = Object.freeze({
    ALL: '0',
    STARTING: '1',
    ENDING: '2',
    STARTING_ENDING: '3'
})

export const TimeIntervalVsEventsDisplayDetails = Object.freeze({
    '0': {
      ICON: <IntervalEventsAllIcon fontSize='small' />,
      HELP: "Tous les évènements",
      HELP2: "se déroulant durant le temps sélectionné"
    },
    '1': {
      ICON: <IntervalEventsStartingIcon fontSize='small' />,
      HELP: "Les évènements qui commencent",
      HELP2: "durant le temps sélectionné"
    },
    '2': {
      ICON: <IntervalEventsEndingIcon fontSize='small' />,
      HELP: "Les évènements qui se terminent",
      HELP2: "durant le temps sélectionné"
    },
    '3': {
      ICON: <IntervalEventsStartingEndingIcon fontSize='small' />,
      HELP: "les évènements qui commencent et se terminent",
      HELP2: "durant le temps sélectionné"
    }
  })
  