import { useState, useEffect } from "react";
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import { evqRecents } from '../../model/Recents'
import { ClearIcon } from '../base/Icons'
import { EvqCardMini } from './EvqCardMini'

export function EvqRecents(props) {
  if (props.show !== true) {
    return null
  }
  return (<EvqRecentsX {...props} />)
}

export function EvqRecentsX(props) {
  const [evQs, setEvQs] = useState([])

  useEffect(() => {
    const list_ = evqRecents.get()
    setEvQs(list_)
  }, [])

  const onClear = e => {
    e.preventDefault()
    evqRecents.clear()
    setEvQs([])
  }

  const onEvqClick = (e,evq) => {
    e.preventDefault()
    props.onEvq && props.onEvq(evq)
  }

  return (
    <>
      { (evQs.length !== 0) &&
      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Dernières recherches</Typography>
          <Divider orientation="vertical" flexItem sx={{ ml: 1.5, mr: 0.5 }} />
          <IconButton size='small' onClick={onClear}>
            <ClearIcon fontSize='small' />
          </IconButton>
        </Box>
        <Stack direction="row" spacing={1} flexWrap="wrap" gap={1} useFlexGap sx={{ mt: 1 }}>
          { evQs.map((evQ_,index) =>
          <EvqCardMini key={index} evq={evQ_} onClick={onEvqClick} />
          )}
        </Stack>
      </Box>
      }
    </>
  )
}
