
export function mainPageUrl(searchParams) {
  return '/?' + searchParams.toString()
}

export function signInRedirectPageUrl() {
  return '/action/sign-in'
}

export function signOutRedirectPageUrl() {
  return '/action/sign-out'
}

export function evPageUrl(evid) {
  return '/event/' + evid
}
