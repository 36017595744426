
export const CircleEvQTypeID = '1'

const _map = Object.freeze({
  query: 'q',
  lon: 'lo',
  lat: 'la',
  label: 'lb',
  radius: 'r',
  timeIntervalID: 'i',
  timeIntervalVsEventsDisplay: 'ie',
  tagIn: 'ti',
  tagOut: 'to',
  sortBy: 'sb',
  pageIndex: 'pi'
})

export function decodeEvQFromUrlParams(searchParams) {
  if (searchParams.has(_map.query)) {
    if (searchParams.get(_map.query) === CircleEvQTypeID) {
      const evQ = {}
      decodeUrlParams(evQ, searchParams, _map)
      return evQ
    }
  }
  return null
}

export function encodeEvQIntoUrlParams(evQ) {
  return encodeUrlParams(evQ, _map)
}

export function encodeUrlParams(evq, map) {
  const params = new URLSearchParams()
  params.set(map.query, CircleEvQTypeID)
  if (evq.place != null) {
    params.set(map.lon, evq.place.lon)
    params.set(map.lat, evq.place.lat)
    params.set(map.label, evq.place.label)
  }
  if (evq.radius != null) {
    params.set(map.radius, evq.radius)
  }
  if (evq.timeIntervalID != null) {
    params.set(map.timeIntervalID, evq.timeIntervalID)
  }
  if (evq.timeIntervalVsEventsDisplay != null) {
    params.set(map.timeIntervalVsEventsDisplay, evq.timeIntervalVsEventsDisplay)
  }
  evq.tagsIn.forEach(tag => {
    params.append(map.tagIn, tag.id)
  })
  evq.tagsOut.forEach(tag => {
    params.append(map.tagOut, tag.id)
  })
  if (evq.sortBy != null) {
    params.set(map.sortBy, evq.sortBy)
  }
  if (evq.pageIndex != null) {
    params.set(map.pageIndex, evq.pageIndex)
  }
  return params
}

export function decodeUrlParams(evq, searchParams, map) {
  // todo: test erroneous values.
  if (searchParams.has(map.lon) && searchParams.has(map.lat)) {
    evq.place = {
      lat: parseFloat(searchParams.get(map.lat)),
      lon: parseFloat(searchParams.get(map.lon)),
      label: (searchParams.has(map.label)) ? searchParams.get(map.label) : '?'
    }
  }
  if (searchParams.has(map.radius)) {
    evq.radius = parseInt(searchParams.get(map.radius))
  }
  if (searchParams.has(map.timeIntervalID)) {
    evq.timeIntervalID = parseInt(searchParams.get(map.timeIntervalID))
  }
  if (searchParams.has(map.timeIntervalVsEventsDisplay)) {
    evq.timeIntervalVsEventsDisplay = searchParams.get(map.timeIntervalVsEventsDisplay)
  }
  evq.tagsIn = []
  if (searchParams.has(map.tagIn)) {
    const tagIDs = [...new Set(searchParams.getAll(map.tagIn))]
    tagIDs.forEach(tagID => {
      evq.tagsIn.push({ id: tagID, name: tagID /* todo: Ttag cache */ })
    })
  }
  evq.tagsOut = []
  if (searchParams.has(map.tagOut)) {
    const tagIDs = [...new Set(searchParams.getAll(map.tagOut))]
    tagIDs.forEach(tagID => {
      evq.tagsOut.push({ id: tagID, name: tagID /* todo: Ttag cache */ })
    })
  }
  if (searchParams.has(map.sortBy)) {
    evq.sortBy = searchParams.get(map.sortBy)
  }
  if (searchParams.has(map.pageIndex)) {
    evq.pageIndex = searchParams.get(map.pageIndex)
  }
}
