import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert';

export default function SignOutRedirectPage(props) {
  const [status, setStatus] = useState({severity: "info", message: "Sign-out..."})
  useEffect(() => {
    const url = new URL(document.location)
    const params = url.searchParams
    if (!(params.has('state'))) {
      setStatus({severity: "error", message: "Malformed URL, state is missing."})
    }
    else {
      setStatus({severity: "success", message: "Sign-out OK"})
      window.opener.postMessage({ state: params.get('state') }, "*")
    }
  }, []);
  return (
    <Box sx={{ p: 1 }}>
      <Alert severity={status.severity}>{status.message}</Alert>
    </Box>
  )
}
