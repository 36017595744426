import { createContext, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Mutex } from 'async-mutex';
import { enqueueSnackbar, closeSnackbar } from 'notistack'
import Button from '@mui/material/Button';
import SignInRedirectPage from "./SignInRedirectPage"
import SignOutRedirectPage from "./SignOutRedirectPage"
import { doSaveTokens, doGetTokens, doClearTokens } from './tokens'

export function SsoProvider(props) {
  switch (window.location.pathname) {
    case props.config.redirectUri:
      return (<SignInRedirectPage />)
    case props.config.postLogoutRedirectUri:
      return (<SignOutRedirectPage />)
    default:
      return (<SsoContextProvider {...props} />)
  }
}

const SsoContext = createContext({});

function SsoContextProvider(props) {
  const mutex = new Mutex()
  const timeoutID = null
  const { t } = useTranslation()
  const [status, setStatus] = useState({ authenticated: (doGetTokens() != null), source: 'init' })
  const ctxt = { config: props.config, mutex, timeoutID, status, setStatus }

  useEffect(() => {
    const tokens_ = doGetTokens()
    if (tokens_ != null) {
      const end_ = moment.unix(tokens_.accessExpiresOn)
      const timeout_ms = end_.diff(moment(), 'milliseconds')
      _armTokenRefreshTimer(ctxt, timeout_ms)
    }
  }, [])

  useEffect(() => {
    if ((status?.source === 'refresh') && (status?.authenticated === false)) {
      enqueueSnackbar(t('session-has-expired'), {
        variant: 'info',
        persist: true,
        preventDuplicate: true,
        action: snackbarId => (
          <Button variant='outlined' size='small' color='inherit' onClick={e => { closeSnackbar(snackbarId) }}>Ok</Button>
        )
      })
    }
  }, [status])

  return (
    <SsoContext.Provider value={ctxt}>
      {props.children}
    </SsoContext.Provider>
  )
}

export function useSsoState() {
  const ctxt = useContext(SsoContext)
  return { ssoState: ctxt.status }
}

export function useSignInOut() {
  const ctxt = useContext(SsoContext)
  const signin = () => _signin(ctxt)
  const signout = () => _signout(ctxt)
  return { signin, signout }
}

export function useTokens() {
  const ctxt = useContext(SsoContext)
  const getAccessToken = () => doGetTokens()?.accessToken
  const refreshTokens = () => _refreshTokens(ctxt)
  return { getAccessToken, refreshTokens }
}

const _armTokenRefreshTimer = (ctxt, timeout_ms) => {
  if (timeout_ms < ctxt.config.refreshTimeoutMin) {
     timeout_ms = ctxt.config.refreshTimeoutMin
     console.log(`armTokenRefreshTimer(${timeout_ms} -> ${ctxt.config.refreshTimeoutMin})`)
  }
  else {
    console.log(`armTokenRefreshTimer(${timeout_ms})`)
  }
  if (ctxt.timeoutID != null) {
    clearTimeout(ctxt.timeoutID)
    ctxt.timeoutID = null
  }
  ctxt.timeoutID = setTimeout(async () => {
    console.log('!time for token refresh')
    await _refreshTokens(ctxt)
  }, timeout_ms)
}

const _disarmTokenRefreshTimer = (ctxt) => {
  console.log("disarmTokenRefreshTimer()")
  clearTimeout(ctxt.timeoutID)
  ctxt.timeoutID = null
}

function _signin(ctxt) {
  const state = window.crypto.randomUUID()
  const params = new URLSearchParams()
  params.set("response_type", "code")
  params.set("client_id", ctxt.config.clientId)
  params.set("redirect_uri", window.location.origin + ctxt.config.redirectUri)
  params.set("state", state)
  const url = ctxt.config.authorizationEndpointUrl + "/?" + params.toString()
  const onSuccess = data => {
    _fetchTokensFromCode(ctxt, data?.code, err => {})
  }
  _openPopup('Sign-in', url, state, onSuccess)
}

function _fetchTokensFromCode(ctxt, code, onError) {
  let query = "grant_type=authorization_code"
  query += "&code=" + code
  query += "&client_id=" + ctxt.config.clientId
  query += "&redirect_uri=" + window.location.origin + ctxt.config.redirectUri
  fetch(ctxt.config.tokenEndpointUrl, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
    body: query
  }).then(
    response => response.json()
  ).then(
    data => {
      if (data?.error) {
        onError(data.error + ', ' + data.error_description)
      }
      else {
        const armTokenRefreshTimer = timeout_ms => _armTokenRefreshTimer(ctxt, timeout_ms)
        doSaveTokens(ctxt, data, armTokenRefreshTimer)
        ctxt.setStatus({ authenticated: true, source: 'user' } )
      }
    }
  ).catch(
    err => { onError(err) }
  )
}

function _signout(ctxt) {
  const state = window.crypto.randomUUID()
  const params = new URLSearchParams()
  params.set("post_logout_redirect_uri", window.location.origin + ctxt.config.postLogoutRedirectUri)
  params.set("state", state)
  const url = ctxt.config.endSessionEndpointUrl + "/?" + params.toString()
  const onSuccess = data => {
    const disarmTokenRefreshTimer = () => _disarmTokenRefreshTimer(ctxt)
    doClearTokens(disarmTokenRefreshTimer)
    ctxt.setStatus({ authenticated: false, source: 'user' } )
  }
  _openPopup('Sign-out', url, state, onSuccess)
}

function _openPopup(title, url, state, onSuccess) {
  const config = {
    height: 500, // pixels
    width: 600 // pixels
  }
  const top  = window.outerHeight / 2 + window.screenY - config.height / 2
  const left = window.outerWidth  / 2 + window.screenX - config.width  / 2
  const popup = window.open(url, title, `height=${config.height},width=${config.width},top=${top},left=${left}`)
  window.addEventListener("message", e => {
    if (e.data?.state === state) {
      popup.close()
      onSuccess && onSuccess(e.data)
    }
  })
}

async function _refreshTokens(ctxt) {
  return await ctxt.mutex.runExclusive(async () => {
    if (doGetTokens()?.refreshToken == null) {
      return null
    }
    const now = moment()
    const timeout = moment.unix(doGetTokens().accessExpiresOn)
    if (now > timeout) {
      await _fetchTokensFromRefresh(ctxt, doGetTokens()?.refreshToken)
    }
    return doGetTokens()?.accessToken
  })
}

async function _fetchTokensFromRefresh(ctxt, refreshToken) {
  console.log('fetchTokensFromRefresh')
  let query = "grant_type=refresh_token"
  query += "&refresh_token=" + refreshToken
  query += "&client_id=" + ctxt.config.clientId
  const response = await fetch(ctxt.config.tokenEndpointUrl, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
    body: query
  })
  if (response.ok) {
    const data = await response.json()
    console.log(data)
    const armTokenRefreshTimer = timeout_ms => _armTokenRefreshTimer(ctxt, timeout_ms)
    doSaveTokens(ctxt, data, armTokenRefreshTimer)
    console.log('fetchTokensFromRefresh ok')
  }
  else {
    if (response.status === 400) {
      // todo: manage silent sign-in
      console.log("fetchTokensFromRefresh got 400")
      const disarmTokenRefreshTimer = () => _disarmTokenRefreshTimer(ctxt)
      doClearTokens(disarmTokenRefreshTimer)
      ctxt.setStatus({ authenticated: false, source: 'refresh' } )
    }
    else {
      const message = `Error on refresh token fetch: ${response.status}`;
      throw new Error(message);
    }
  }
}
