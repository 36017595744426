import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Zoom from '@mui/material/Zoom'
import Typography from '@mui/material/Typography'
import EvTile from "./EvTile"

function PaginationX(props) {
  return (
    <>
      { (props.page != null) &&
      <Pagination sx={{ display: 'flex', justifyContent: 'center', m: 2}}
        variant="outlined"
        hidePrevButton
        hideNextButton
        page={props.page || 1}
        count={props.count || 1}
        onChange={props.onChange}
      />
      }
    </>
  )
}

function ButtonScrollToTop(props) {
  const trigger = useScrollTrigger({ threshold: 300, disableHysteresis: true })
  return (
    <Zoom in={trigger}>
      <Fab
        size="large"
        sx={{position: 'fixed', bottom: 0, right: 0, m: 2 }}
        onClick={e => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ArrowUpwardIcon />
      </Fab>
    </Zoom>
  )
}

export default function EvGrid(props) {
  const onPageChange = pageNum => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (props.onPageChange) {
      props.onPageChange(pageNum)
    }
  }
  if (props.loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.loading || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }
  if (props.events == null) {
    return (<></>)
  }
  if (props.events.length === 0) {
    return (
      <>
        <br/>
        <Typography align='center'>Pas d'évènement :-/</Typography>
      </>
    )
  }
  return (
    <Box>
      <PaginationX
        page={props.pageNum}
        count={props.pageCount}
        onChange={(e,pageNum) => onPageChange(pageNum)}
      />
      <Grid container spacing={1}>
        { props.events.map((ev) =>
        <Grid item key={ev.id} xs={12} sm={6} md={4} xl={3}>
          <EvTile
            ev={ev}
            onEvClick={props.onEvClick}
            onTagClick={props.onTagClick}
            refPlace={props.refPlace}
          />
        </Grid>
        )}
      </Grid>
      <PaginationX
        page={props.pageNum}
        count={props.pageCount}
        onChange={(e,pageNum) => onPageChange(pageNum)}
      />
      <ButtonScrollToTop />
    </Box>
  )
}
