import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { useLazyQuery, gql } from "@apollo/client"
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { EvPopup } from './EvPopup'
import { evTransform } from '../../model/Ev'
import { EvCardLine } from './EvCardLine'
import { evRecents } from '../../model/Recents'
import { ClearIcon } from '../base/Icons'

const GET_EVENTS_BY_ID = gql`
query getEventsById($ids: [String]!) {
  getEventsById(ids: $ids) {
    id
    name
  }
}`

export function EvRecents(props) {
  if (props.show !== true) {
    return null
  }
  return (<EvRecentsX />)
}

function EvRecentsX(props) {
  const { t } = useTranslation()
  const [evIdObjs, setEvIdObjs] = useState([])
  const [openEvPopup, setOpenEvPopup]  = useState([false, null])
  // eslint-disable-next-line no-unused-vars
  const [getEventsById, { called, loading, data_ }] = useLazyQuery(GET_EVENTS_BY_ID)

  useEffect(() => {
    const ids_ = evRecents.get()
    const idObjs_ = ids_.map((x) => [x, null] )
    setEvIdObjs(idObjs_)
    getEventsById({
      variables: {
        ids: ids_
      },
      onCompleted: data => {
        const idObjs_ = data.getEventsById.map((x) => [x.id, evTransform(x)])
        setEvIdObjs(idObjs_)
      },
      onError: err => {
        // nop
      }
    })
  }, [])

  const onEvClick = (e,evid,ev) => {
    e.preventDefault()
    if (ev == null) { return }
    setOpenEvPopup([true, ev])
  }
  const onEvClose = (e,evid,ev) => {
    e.preventDefault()
    if (evid == null) { return }
    evRecents.delete(evid)
    const idObjs_ = evIdObjs.filter((idObj) => idObj[0] !== evid)
    setEvIdObjs(idObjs_)
  }
  const onClear = e => {
    e.preventDefault()
    evRecents.clear()
    setEvIdObjs([])
  }
  return (
    <>
      { (evIdObjs.length !== 0) &&
      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{t('recently-seen')}</Typography>
          <Divider orientation="vertical" flexItem sx={{ ml: 1.5, mr: 0.5 }} />
          <IconButton size='small' onClick={onClear}>
            <ClearIcon fontSize='small' />
          </IconButton>
        </Box>
        <Stack direction="row" spacing={1} flexWrap="wrap" gap={1} useFlexGap sx={{ mt: 1 }}>
          { evIdObjs.map(evIdObj =>
          <EvCardLine key={evIdObj[0]} ev={evIdObj[1]} onClick={onEvClick} onClose={onEvClose} />
          )}
        </Stack>
        <EvPopup
          open={openEvPopup[0]}
          onClose={e => setOpenEvPopup([false, openEvPopup[1]]) }
          ev={openEvPopup[1]}
        />
      </Box>
      }
    </>
  )
}
