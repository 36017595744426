import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert';

export default function SignInRedirectPage(props) {
  const [status, setStatus] = useState({severity: "info", message: "Sign-in..."})
  useEffect(() => {
    const url = new URL(document.location)
    const params = url.searchParams
    if (!(params.has('code'))) {
      setStatus({severity: "error", message: "Malformed URL, code is missing."})
    }
    else if (!(params.has('state'))) {
      setStatus({severity: "error", message: "Malformed URL, state is missing."})
    }
    else {
      setStatus({severity: "success", message: "Sign-in OK"})
      window.opener.postMessage({ state: params.get('state'), code: params.get('code') }, "*")
    }
  }, []);
  return (
    <Box sx={{ p: 1 }}>
      <Alert severity={status.severity}>{status.message}</Alert>
    </Box>
  )
}
