import { SsoProvider } from './sso'
import { signInRedirectPageUrl, signOutRedirectPageUrl } from './utils/routing.js'

export default function AppSsoProvider(props) {
  const ssoConfig = {
    authorizationEndpointUrl: "https://sso.lezoom.fr/realms/LZ/protocol/openid-connect/auth",
    tokenEndpointUrl:         "https://sso.lezoom.fr/realms/LZ/protocol/openid-connect/token",
    endSessionEndpointUrl:    "https://sso.lezoom.fr/realms/LZ/protocol/openid-connect/logout",
    clientId:                 "lezoom_frontend",
    redirectUri:              signInRedirectPageUrl(),
    postLogoutRedirectUri:    signOutRedirectPageUrl(),
    refreshMargin:            10, // in seconds
    refreshTimeoutMin:        1000 // in milliseconds
  }
  return (
    <SsoProvider config={ssoConfig}>
      {props.children}
    </SsoProvider>
  )
}
