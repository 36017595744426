import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import { MenuIcon } from '../base/Icons'

export default function ButtonMenu(props) {
  const [showMenu, setShowMenu] = useState(false)
  const [anchorElement, setAnchorElement] = useState(null)
  const onMenuOpen = e => {
    //setShowMenu(true)
    //setAnchorElement(e.currentTarget)
  }
  const onMenuClose = e => {
    setShowMenu(false)
  }
  return (
    <>
      <IconButton size='large' onClick={onMenuOpen}><MenuIcon /></IconButton>
      <Menu open={showMenu}
            onClose={onMenuClose}
            anchorEl={anchorElement}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <MenuItem>
          <ListItemText>Beta version</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
