import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import { CloseWhiteIcon } from '../base/Icons'

export default function TagChip(props) {

  const onClick = (props.onClick == null) ? null : e => {
    e.preventDefault()
    props.onClick(props.tag)
    e.stopPropagation()
  }

  const onDelete = (props.onDelete == null) ? null : e => {
    e.preventDefault()
    props.onDelete(e, props.tag)
    e.stopPropagation()
  }

  const onMouseDown = e => e.stopPropagation()

  const backgroundColor = (props.blacklisted) ? '#666'  : (props.tag.bgColor || '#000')
  const color = (props.blacklisted) ? '#fff' : (props.tag.color || '#fff')
  const textDecoration = (props.blacklisted) ? 'line-through' : 'none'
  const radius = '3px'

  return (
    <Chip
      label={props.tag.name}
      size={props.size || 'medium'}
      deleteIcon={<CloseWhiteIcon fontSize='small' />}
      sx={{
        m: '1px',
        borderTopLeftRadius: (['top', 'left'].includes(props.edged) ? '0' : radius),
        borderBottomLeftRadius: (['bottom', 'left'].includes(props.edged) ? '0' : radius),
        borderTopRightRadius: (['top', 'right'].includes(props.edged) ? '0' : radius),
        borderBottomRightRadius: (['bottom', 'right'].includes(props.edged) ? '0' : radius),
        backgroundColor: backgroundColor,
        color: color,
        ":hover": {
          bgcolor: backgroundColor,
          color: color
        },
        textDecoration: textDecoration
      }}
      onClick={onClick}
      onDelete={onDelete}
      onMouseDown={onMouseDown}
    />
  )
}

TagChip.propTypes = {
  tag: PropTypes.object.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func
}
