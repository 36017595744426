import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import passwordValidator from 'password-validator'

function TextFieldPassword(props) {
  const [showPassword, setShowPassword] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)
  const [messages, setMessages] = useState([])
  const schema = new passwordValidator()

  schema
    .is().min(8)
    .is().max(32)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1)
    .has().symbols(1)
    .has().not().spaces()
    .is().not().oneOf(['abcde', '12345', 'QWERTY', 'AZERTY'])

  const onFocus = e => {
    const details = schema.validate(e.target.value, { details: true })
    setMessages(details)
    setOpenTooltip(true)
  }

  const onChange = e => {
    const details = schema.validate(e.target.value, { details: true })
    setMessages(details)
    if (details.length === 0) {
      e.target.setCustomValidity("")
    }
    else {
      e.target.setCustomValidity("The password does not match rules.")
    }
  }

  const onBlur = e => {
    setOpenTooltip(false)
  }

  return (
    <Tooltip
      open={openTooltip}
      title={(messages.length === 0) ? null :
        <>
          {messages.map((val, idx) =>
            <Typography key={idx}>{val.message}</Typography>
          )}
        </>
      }
      placement='top'
      arrow
    >
      <TextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ pr: 0.5 }}>
              <IconButton edge='end'
                onClick={e => setShowPassword(!showPassword)}
                onMouseDown={e => e.preventDefault()}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Tooltip>
  )
}

export default TextFieldPassword
