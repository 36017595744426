import 'moment/locale/fr'
import 'moment/locale/de'
import moment from 'moment'
import { dtFormatEvStartEnd } from '../utils/dtFormat'

export function startMomentShortStr(ev, locale) {
  return (ev.startMomentFmt == null) ? null : ev.startMoment.locale(locale).format(ev.startMomentFmt[locale])
}

export function endMomentShortStr(ev, locale) {
  return (ev.endMomentFmt == null) ? null : ev.endMoment.locale(locale).format(ev.endMomentFmt[locale])
}

export function evTransform(ev) {
  const ev_ = {...ev}

  // LzEvent
  // id -> Event.identifier
  // name (no change)
  // title -> delete
  // location -> location.name & location.address
  // starttime -> startDate
  // endtime -> endDate
  // coordinates -> location.longitude & location.latitude
  ev_.__typename = 'LzEvent'
  // keep ev_.name
  delete ev_.title
  ev_.startDate = ev_.starttime; delete ev_.starttime
  ev_.endDate   = ev_.endtime;   delete ev_.endtime
  if ('address' in ev_) { delete ev_.address }
  if ('allDayEvent' in ev_) { delete ev_.allDayEvent }
  ev_.reporter = ev_.creator; delete ev_.creator // replace with 'reporter' ? LzEvent property.
  ev_.reportDate = ev_.createdat; delete ev_.createdat // // replace with 'reportDate' ? LzEvent property.
  // keep ev_.description
  delete ev_.eventlocation
  if ('externalId' in ev_) { delete ev_.externalId }

  // Tags is a new field of LzEvent
//if (ev_.tags) {
//  ev_.tags.forEach(tag => {
//    tag.__typename = 'LzTag'
//  })
//} 

  // LzPlace
  // id -> Place.identifier
  let place_ = null

  if (ev_.location != null) {
    let address_ = ev_.location
    address_ = address_.replace(' - ', '\n') // replace ' - ' with \n
    address_ = address_.split("\n")          // split in list.
    address_ = [...new Set(address_)]        // remove duplicate
    const postcode_city_ = address_[address_.length-1]
    const postcode_ = postcode_city_.match(/\b\d{5}\b/g)[0]
    const city_ = postcode_city_.replace(/[0-9]{5} /g, '') // remove postal code.
    if (place_ == null) {
      place_ = { __typename: 'LzPlace'}
    }
    place_.name = address_[0]
    place_.address = {
      __typename: 'PostalAddress',
      streetAddress: ((address_.length >= 3) ? address_[1] : undefined),
      postalCode: postcode_,
      addressLocality: city_
    }
    delete ev_.location
  }

  if (ev_.coordinates != null) {
    if (place_ == null) {
      place_ = { __typename: 'LzPlace'}
    }
    place_.longitude = ev_.coordinates[0]
    place_.latitude = ev_.coordinates[1]
    delete ev_.coordinates
  }

  if (place_) {
    ev_.location = place_
  }

  // Extensions. todo: remove?
  ev_.startMoment = (ev_.startDate == null) ? null : moment(ev_.startDate)
  ev_.endMoment   = (ev_.endDate == null) ? null : moment(ev_.endDate)
  ev_.fullDays = (ev_.endMoment && (ev_.startMoment.hours() === 0) && (ev_.startMoment.minutes() === 0) && (ev_.endMoment.hours() === 23) && (ev_.endMoment.minutes() === 59));
  [ev_.startMomentFmt, ev_.endMomentFmt] = dtFormatEvStartEnd(ev_.startMoment, ev_.endMoment, ev_.fullDays)

  return ev_
}
