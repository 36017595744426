import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea'
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography';
import { TimeIntervalVsEventsDisplayDetails } from '../../defs/TimeIntervalVsEventsDisplay'
import { SortByDetails } from '../../defs/SortBy'
import TagChip from '../tag/TagChip'

export function EvqCardMini(props) {
  const { t } = useTranslation()
  const onClick = (props.onClick == null) ? null : e => { props.onClick(e, props.evq) }
  const onClose = (props.onClose == null) ? null : e => { props.onClose(e, props.evq) }
  return (
    <Card elevation={0} sx={{ borderRadius: 1, display: 'flex', gap: 0, alignItems: 'center' }}>
      <CardActionArea sx={{ m: '2px', p: 1, pl: 2, pr: 2 }} onClick={onClick}>
        <Typography variant='body1'>{props.evq.place.label} ↔ {props.evq.radius} km</Typography>
        <Typography variant='body1'>timeIntervalID = {props.evq.timeIntervalID}</Typography>
        <Typography variant='body1'>({TimeIntervalVsEventsDisplayDetails[props.evq.timeIntervalVsEventsDisplay].HELP} {TimeIntervalVsEventsDisplayDetails[props.evq.timeIntervalVsEventsDisplay].HELP2})</Typography>
        { props.evq.tagsIn && (props.evq.tagsIn.length !== 0) && props.evq.tagsIn.map((tag, index) => (
        <TagChip key={index} tag={tag} size='small' />
        ))}
        { props.evq.tagsOut && (props.evq.tagsOut.length !== 0) && props.evq.tagsOut.map((tag, index) => (
        <TagChip key={index} tag={tag} size='small' blacklisted={true} />
        ))}
        <Typography variant='body1'>{t(SortByDetails[props.evq.sortBy].LABEL)}</Typography>
      </CardActionArea>
      <Divider orientation="vertical" flexItem variant="middle" />
      <IconButton size='small' sx={{ m: 0.5 }} onClick={onClose}>
        <CloseIcon fontSize='small' color='contrasting' />
      </IconButton>
    </Card>
  )
}
